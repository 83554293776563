import React, { useContext, createContext } from 'react'

export const ChatComponentsContext = createContext({})
ChatComponentsContext.displayName = 'ChatComponentsContext'

export const ChatComponentProvider = ({
  children,
  defaultChatComponents = {},
  customChatComponents = {},
}) => {
  const chatComponentsConfig = {
    ...defaultChatComponents,
    ...customChatComponents,
  }

  return (
    <ChatComponentsContext.Provider value={{ chatComponentsConfig }}>
      {children}
    </ChatComponentsContext.Provider>
  )
}

export const useChatComponents = (componentName = 'error') => {
  const context = useContext(ChatComponentsContext)

  if (context === undefined) {
    throw new Error('useChatComponents must be nested in ChatComponentProvider')
  }

  const { chatComponentsConfig } = context

  const ChatComponent = chatComponentsConfig[componentName] || chatComponentsConfig.error

  return { ChatComponent }
}
